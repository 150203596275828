import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Burger from '../Burger/Burger';

const Header = styled.header`
  box-shadow: 0 2px 4px hsl(0 0% 30% / 30%);
  width: 100%;
  left: 0;
  top: 0;
  z-index: 11;
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'static')};

  ${({ theme }) => theme.mqTablet} {
    background: none;
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(250,250,250,.8);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      z-index: -1;
    }
  }

  .logo {
    position: relative;
    z-index: 4;
  }

  ${({ theme }) => theme.mqMobile} {
    .btn-fb {
      display: none;
    }
  }

  img {
    height: auto;
    margin-top: 8px;
    width: 135px;

    ${({ theme }) => theme.mqLaptop} {
      width: 200px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 70px;
    padding: 5px 15px;
  }

  ${({ theme }) => theme.mqTablet} {
    height: auto;
    position: sticky;

    .row {
      align-items: center;
      padding: 0 15px;
    }
  }
`;

const NavWrap = styled.div`
  align-items: center;
  background: white;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  left: 0;
  padding: 80px 0 0;
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'static')};;
  top: 0;
  width: 100%;
  z-index: 3;
  
  ${({ theme }) => theme.mqTablet} {
    background: none;
    display: flex;
    height: auto;
    position: relative;
    padding: 0;
    flex-direction: row;
  }
`;

const Nav = styled.nav`

  a {
    color: #323232;
    display: block;
    font-size: 2.4rem;
    font-weight: 600;
    height: auto;
    line-height: normal;
    padding: 3px 0;
    text-decoration: none;

    &:hover,
    &:focus,
    &.active {
      color: ${({ theme }) => theme.primary};
      text-decoration: none;
    }
  }

  li {
    margin: 0 0 10px;

    &.disabled {
      display: none;
    }
  }

  ${({ theme }) => theme.mqMobile} {
    overflow: auto;
    padding: 0 0 40px;
    width: 100%;

    a {
      position: relative;

      &::after {
        border-bottom: 4px solid hsl(347,73%,71%);
        bottom: -5px;
        content: "";
        left: 0;
        position: absolute;
        width: 50px;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0 20px;

      ul {
        list-style: disc;
        margin: 15px 0 0 25px;

        li {
          margin: 5px 0;
        }

        a {
          font-size: 1.8rem;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  ${({ theme }) => theme.mqTablet} {
    margin-left: 40px;
    margin-right: auto;

    a {
      font-weight: 500;
      font-size: 2rem;
    }

    li {
      &.disabled {
        display: block;

        a {
          color:#000;
          opacity: .25;
          pointer-events: none;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;

      > li {
        display: inline-block;
        margin: 0 20px;
        position: relative;

        &.submenu {
          > a {
            padding-right: 17px;

            &::after {
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 6px solid #323232;
              content: '';
              position: absolute;
              top: calc(50% - 2px);
              right: 0;
            }
          }

          &:hover,
          &:focus {
            ul {
              display: block;
            }
          }

          ul {
            /* background: ${({ theme }) => theme.white}; */
            border: 1px solid #ddd;
            border-top: 0;
            box-shadow: 0 5px 5px -5px hsl(0 0% 30% / 30%);
            display: none;
            left: -18px;
            padding: 5px 20px;
            position: absolute;
            top: 70px;
            width: 250px;
            z-index: -1;
            
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background: rgba(250,250,250,.8);
              -webkit-backdrop-filter: blur(20px);
              backdrop-filter: blur(20px);
              z-index: -1;
            }

            ${({ theme }) => theme.mqLaptop} {
              top: 80px;
            }

            li {
              display: block;
              margin: 0;

              & + li {
                border-top: 1px solid #ddd;
              }
            }

            a {
              display: block;
              font-size: 1.6rem;
              height: auto;
              line-height: normal;
              padding: 10px 0;
            }
          }
        }
      }
    }

    a {
      ${({ theme }) => theme.mqTablet} {
        line-height: 70px;
        padding: 0;
      }

      ${({ theme }) => theme.mqLaptop} {
        line-height: 80px;
        font-size: 2rem;
      }
    }
  }
`;

export const HeaderComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Header isOpen={isOpen}>
      <div className="row">
        <Link
          to="/"
          title="Elżbieta - Gabinet Kosmetyki Leczniczej"
          className="logo"
          activeClassName="active"
        >
          <img
            alt="Gabinet Elżbieta"
            src="/logo.webp"
            width="169"
            height="56"
          />
        </Link>

        <NavWrap isOpen={isOpen}>
          <Nav>
            <ul>
              <li className="submenu">
                <Link to="/o-gabinecie/" activeClassName="active" onClick={toggleNavigation}>O gabinecie</Link>
                <ul aria-label="submenu">
                  <li>
                    <Link to="/o-gabinecie/wyroznienie/" activeClassName="active" onClick={toggleNavigation}>Wyróżnienie</Link>
                  </li>
                  <li>
                    <Link to="/o-gabinecie/sprzedaz-kosmetykow/" activeClassName="active" onClick={toggleNavigation}>Sprzedaż</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <Link to="/oferta/" activeClassName="active" onClick={toggleNavigation}>Oferta</Link>

                <ul aria-label="submenu">
                  <li>
                    <Link to="/oferta/elektroliza/" activeClassName="active" onClick={toggleNavigation}>Elektroliza</Link>
                  </li>
                  <li>
                    <Link to="/oferta/piercing-uszu/" activeClassName="active" onClick={toggleNavigation}>Piercing uszu</Link>
                  </li>
                  <li>
                    <Link to="/oferta/kosmetyka-mezczyzn/" activeClassName="active" onClick={toggleNavigation}>Kosmetyka mężczyzn</Link>
                  </li>
                  <li>
                    <Link to="/oferta/dermapen/" activeClassName="active" onClick={toggleNavigation}>Dermapen</Link>
                  </li>
                  <li>
                    <Link to="/oferta/peeling-kawitacyjny/" activeClassName="active" onClick={toggleNavigation}>Peeling kawitacyjny</Link>
                  </li>
                  <li>
                    <Link to="/oferta/henna/" activeClassName="active" onClick={toggleNavigation}>Henna</Link>
                  </li>
                  <li>
                    <Link to="/oferta/mikrodermabrazja/" activeClassName="active" onClick={toggleNavigation}>Mikrodermabrazja</Link>
                  </li>
                  <li>
                    <Link to="/oferta/depilacja-woskiem/" activeClassName="active" onClick={toggleNavigation}>Depilacja woskiem</Link>
                  </li>
                  <li>
                    <Link to="/oferta/sonoforeza/" activeClassName="active" onClick={toggleNavigation}>Sonoforeza</Link>
                  </li>
                  <li>
                    <Link to="/oferta/retix-c/" activeClassName="active" onClick={toggleNavigation}>Retix C</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <Link to="/cennik/" activeClassName="active" onClick={toggleNavigation}>Cennik</Link>
                <ul aria-label="submenu">
                  <li>
                    <Link to="/cennik/pomysl-na-prezent/" activeClassName="active" onClick={toggleNavigation}>Pomysł na prezent</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/kontakt/" activeClassName="active" onClick={toggleNavigation}>Kontakt</Link>
              </li>
            </ul>
          </Nav>

          <div>
            <a title="Sprawdź nasz profil na Facebooku" href="https://www.facebook.com/profile.php?id=100063899109817" rel="nofollow" className="btn-fb">
              <svg
                version="1.1"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 96.124 96.123"
                xmlSpace="preserve"
              >
                <g>
                  <path d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z" />
                </g>
              </svg>
            </a>
          </div>
        </NavWrap>

        <Burger isOpen={isOpen} setIsOpen={setIsOpen} title="Menu" />
      </div>
    </Header>
  );
};

export default HeaderComponent;
