import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }

  body {
    color: #323232;
    margin: 0;
    font-size: 1.7rem;
    font-family: "Lato", sans-serif;

    @media only screen and (min-width: 1024px) {
      font-size: 1.9rem;
    }
  }

  address {
    font-style: normal;
  }

  h1,h2,h3,h4,h5,h6,.heading__title {
    font-family: "Cormorant Garamond", serif;
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 3.5rem 0 1.5rem;
  }

  @keyframes slidein {
    0% {
     transform: translateX(-100%);
    }

    100% {
     transform: translateX(0);
    }
  }

  h1 {
    padding: 0 0 15px;
    font-size: 3.2rem;
    font-weight: 600;
    margin: .5rem 0 3.5rem;
    overflow: hidden;
    position: relative;

    @media only screen and (min-width: 1024px) {
      font-size: 4.2rem;
    }

    &::after {
      border-bottom: 4px solid hsl(347, 73%, 71%);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      width: 75px;
      animation: 400ms forwards alternate slidein;
      animation-delay: 200ms;
      transform: translateX(-100%);
    }
  }

  h2 {
    font-size: 2.6rem;

    @media only screen and (min-width: 1024px) {
      font-size: 3.4rem;
    }
  }

  h3 {
    font-size: 1.8rem;

    @media only screen and (min-width: 1024px) {
      font-size: 2.8rem;
    }
  }

  p {
    line-height: 160%;
  }

  .subtitle {
    font-size: 1.5rem;
    margin: 0;
    text-transform: uppercase;
  }

  a {
    transition: color 150ms ease-in;
    color: hsl(347,84%,48%);
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  address, dl {
    line-height: 150%;
  }
  
  img {
    max-width: 100%;
  }

  button {
    cursor: pointer;
  }

  figcaption {
    font-style: italic;
    line-height: 130%;
    margin: 15px 0 0;
    font-size: 1.6rem;
  }

  main {
    margin: 50px auto 100px;
    min-height: 330px;

    iframe {
      border: 0;
      max-width: 100%;
    }

    @media only screen and (min-width: 1024px) {
      margin: 100px 0 150px;
    }
  }

  .row {
    padding: 0 15px;
    margin-inline: auto;
    width: min(100%, 1140px);
  }

  .box {
    padding: 50px 0;

    @media only screen and (min-width: 800px) {
      padding: 75px 0;
    }
  }

  .lead {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 4rem;

    @media only screen and (min-width: 800px) {
      font-size: 2.2rem;
      max-width: 86%;
    }
  }

  .text-center {
    text-align: center;
  }

  .no-marg {
    margin: 0;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .col {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &--invert {
      .col--6 {
        &:nth-child(2) {
          order: -1;
        }
      }
    }

    @media only screen and (min-width: 960px) {
      flex-direction: row;

      &--6 {
        width: calc(50% - 20px);
      }
    }

    img {
      margin: 0 auto 20px;
      max-width: 550px;
      width: 100%;
    }
  }

  .btn-fb {
    transition: background 150ms ease-in;
    background: rgb(235, 127, 150);
    border: 2px solid rgb(235, 127, 150);
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: inline-block;
    line-height: 52px;
    font-weight: 600;
    text-align: center;

    svg {
      path {
        transition: fill 150ms ease-in;
        fill: #fff;
      }
    }

    &:hover,
    &:focus {
      background: white;
      text-decoration: none;

      svg {
        path {
          fill: rgb(235, 127, 150);
        }
      }
    }
  }

  .btn {
    transition: all 150ms ease-in;
    color: hsl(347deg 100% 35%);
    border-radius: 5px;
    background: #fff;
    border: 2px solid hsl(347, 84%, 48%);
    display: inline-block;
    max-width: 140px;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    width: 100%;

    &:hover,
    &:focus {
      background: hsl(347, 84%, 48%);
      color: #fff;
      text-decoration: none;
    }

    &--auto {
      width: max-content;
      max-width: none;
    }
  }

  .btn--send {
    background: hsl(347, 84%, 48%);
    border: 2px solid hsl(347, 84%, 48%);
    color: #fff;

    &:hover,
    &:focus {
      background: #fff;
      color: hsl(347, 84%, 48%);
      text-decoration: none;

      svg {
        path {
          fill: hsl(347, 84%, 48%);
        }
      }
    }

    svg {
      path {
        transition: fill 200ms ease-in;
        fill: #fff;
      }
    }
  }
  
`;
