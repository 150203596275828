/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import styled from 'styled-components';
// import { Link } from 'gatsby';

const Info = styled.section`
  background: ${({ theme }) => theme.grey};
  color: ${({ theme }) => theme.white};
  padding: 70px 0;

  address {
    font-style: normal;
  }
`;

const InfoAbout = styled.section`
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 45px 0;

  .InfoAbout__item {
    text-align: center;

    .text-uppercase {
      margin: 0 0 10px;
    }

    a {
      color: ${({ theme }) => theme.white};
    }
  }

  .info-heading {
    font-family: ${({ theme }) => theme.font.family.cormorant};
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0 0 30px;
    position: relative;

    &::after {
      bottom: -8px;
      content: '';
      border-bottom: 2px solid ${({ theme }) => theme.primaryLight};
      position: absolute;
      width: 50px;
      height: 1px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
`;

const Footer = styled.footer`
  padding: 15px 0;

  p {
    font-size: 1.4rem;
    line-height: 150%;
    font-family: ${({ theme }) => theme.font.family.sans};
    margin: 0;
  }
`;

export const FooterComponent = () => (
  <>
    <Info>
      <div className="row">
        <InfoAbout>
          <div className="InfoAbout__item">
            <p className="text-uppercase">Nowa lokalizacja</p>
            <p className="info-heading">Adres</p>
            <address itemScope="itemscope" itemType="http://www.schema.org/LocalBusiness">
              <div>
                <span itemProp="postalCode">10-520</span>
                {' '}
                <span itemProp="addressLocality">Olsztyn</span>
              </div>
              <div itemProp="streetAddress">ul. Mazurska 6B</div>
            </address>
          </div>

          <div className="InfoAbout__item">
            <p className="text-uppercase">Umów wizytę</p>
            <p className="info-heading">Kontakt</p>
            <p className="no-marg">
              <a href="tel:+48 516 908 117" itemProp="telephone">+48 516 908 117</a>
            </p>
          </div>

          <div className="InfoAbout__item">
            <p className="text-uppercase">Bądź na bieżąco</p>
            <p className="info-heading">Znajdź nas na:</p>
            <p className="no-marg">
              <a title="Odwiedź nasz profil na Facebooku" href="https://www.facebook.com/profile.php?id=100063899109817" rel="nofollow" className="btn-fb">
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  width="20px"
                  height="20px"
                  viewBox="0 0 96.124 96.123"
                  xmlSpace="preserve"
                >
                  <g>
                    <path d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z" />
                  </g>
                </svg>
              </a>
            </p>
          </div>
        </InfoAbout>
      </div>
    </Info>
    <Footer>
      <div className="row">
        <p>© 1984-{new Date().getFullYear()} | Gabinet Kosmetyki Leczniczej Elżbieta</p>
      </div>
    </Footer>
  </>
);

export default FooterComponent;
