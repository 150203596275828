exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cennik-jsx": () => import("./../../../src/pages/cennik.jsx" /* webpackChunkName: "component---src-pages-cennik-jsx" */),
  "component---src-pages-cennik-pomysl-na-prezent-jsx": () => import("./../../../src/pages/cennik/pomysl-na-prezent.jsx" /* webpackChunkName: "component---src-pages-cennik-pomysl-na-prezent-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-gabinecie-jsx": () => import("./../../../src/pages/o-gabinecie.jsx" /* webpackChunkName: "component---src-pages-o-gabinecie-jsx" */),
  "component---src-pages-o-gabinecie-sprzedaz-kosmetykow-jsx": () => import("./../../../src/pages/o-gabinecie/sprzedaz-kosmetykow.jsx" /* webpackChunkName: "component---src-pages-o-gabinecie-sprzedaz-kosmetykow-jsx" */),
  "component---src-pages-o-gabinecie-wyroznienie-jsx": () => import("./../../../src/pages/o-gabinecie/wyroznienie.jsx" /* webpackChunkName: "component---src-pages-o-gabinecie-wyroznienie-jsx" */),
  "component---src-pages-oferta-depilacja-woskiem-jsx": () => import("./../../../src/pages/oferta/depilacja-woskiem.jsx" /* webpackChunkName: "component---src-pages-oferta-depilacja-woskiem-jsx" */),
  "component---src-pages-oferta-dermapen-jsx": () => import("./../../../src/pages/oferta/dermapen.jsx" /* webpackChunkName: "component---src-pages-oferta-dermapen-jsx" */),
  "component---src-pages-oferta-elektroliza-jsx": () => import("./../../../src/pages/oferta/elektroliza.jsx" /* webpackChunkName: "component---src-pages-oferta-elektroliza-jsx" */),
  "component---src-pages-oferta-henna-jsx": () => import("./../../../src/pages/oferta/henna.jsx" /* webpackChunkName: "component---src-pages-oferta-henna-jsx" */),
  "component---src-pages-oferta-jsx": () => import("./../../../src/pages/oferta.jsx" /* webpackChunkName: "component---src-pages-oferta-jsx" */),
  "component---src-pages-oferta-kosmetyka-mezczyzn-jsx": () => import("./../../../src/pages/oferta/kosmetyka-mezczyzn.jsx" /* webpackChunkName: "component---src-pages-oferta-kosmetyka-mezczyzn-jsx" */),
  "component---src-pages-oferta-mikrodermabrazja-jsx": () => import("./../../../src/pages/oferta/mikrodermabrazja.jsx" /* webpackChunkName: "component---src-pages-oferta-mikrodermabrazja-jsx" */),
  "component---src-pages-oferta-peeling-kawitacyjny-jsx": () => import("./../../../src/pages/oferta/peeling-kawitacyjny.jsx" /* webpackChunkName: "component---src-pages-oferta-peeling-kawitacyjny-jsx" */),
  "component---src-pages-oferta-piercing-uszu-jsx": () => import("./../../../src/pages/oferta/piercing-uszu.jsx" /* webpackChunkName: "component---src-pages-oferta-piercing-uszu-jsx" */),
  "component---src-pages-oferta-retix-c-jsx": () => import("./../../../src/pages/oferta/retix-c.jsx" /* webpackChunkName: "component---src-pages-oferta-retix-c-jsx" */),
  "component---src-pages-oferta-sonoforeza-jsx": () => import("./../../../src/pages/oferta/sonoforeza.jsx" /* webpackChunkName: "component---src-pages-oferta-sonoforeza-jsx" */)
}

